<script>
import { GetYearMonthlySales } from "@/utils/api";
/**
 * Revenue Analytics component
 */
export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [5, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      YearTotal: 0,
      lastYearTotal: 0,
      thisMouth: 0,
      growthRate: 0,
      YearGrowthRate: 0,
      thisMouthDirection: 0,
      thisYearDirection: 0,
    };
  },
  created() {
    this.GetSalesData();
  },
  methods: {
    GetSalesData() {
      GetYearMonthlySales().then((res) => {
        this.YearTotal = ((res.data.Year.reduce(
          (sum, item) => sum + item.total,
          0
        ))/100/7.0498).toFixed(3);
        this.lastYearTotal = ((res.data.lastYear.reduce(
          (sum, item) => sum + item.total,
          0
        ))/100/7.0498).toFixed(3);
        const lastItem = res.data.Year[res.data.Year.length - 1];
        const lastItem1 = res.data.Year[res.data.Year.length - 2];
        this.thisMouth = (lastItem.total / 100 / 7.0498).toFixed(3);
        this.growthRate = (lastItem.total / lastItem1.total).toFixed(3);
        this.thisMouthDirection = this.growthRate >= 1 ? true : false;
        this.YearGrowthRate = (this.YearTotal / this.lastYearTotal).toFixed(3);
        this.thisYearDirection = this.YearGrowthRate >= 1 ? true : false;
        console.log("res.data.Year", res.data.Year);
        this.series = [
          {
            name: "2023",
            type: "column",
            data: res.data.lastYear.map((item) =>
              (item.total / 100 / 7.0498).toFixed(3)
            ), // 提取2023年的总数
          },
          {
            name: "2024",
            type: "column",
            data: res.data.Year.map((item) =>
              (item.total / 100 / 7.0498).toFixed(3)
            ), // 提取2024年的总数
          },
        ];
      });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Sales analysis</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-4">
          <p class="text-muted text-truncate mb-0">This month</p>
          <div class="d-inline-flex">
            <h5 class="mr-2">${{ thisMouth }}</h5>
            <div class="text-success">
              <i
                :class="
                  thisMouthDirection
                    ? 'mdi mdi-menu-up font-size-14'
                    : 'mdi mdi-menu-down font-size-14'
                "
              ></i
              >{{ growthRate }} %
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This
              Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">$ {{ YearTotal }}</h5>
              <div class="text-success">
                <i
                  :class="
                    thisYearDirection
                      ? 'mdi mdi-menu-up font-size-14'
                      : 'mdi mdi-menu-down font-size-14'
                  "
                ></i
                >{{ YearGrowthRate }} %
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              Previous Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">$ {{ lastYearTotal }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>